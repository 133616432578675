.custom-item {
  background-color: #fff;
  border-radius: 50px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  min-width: 25px;
  min-height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2px;
  margin-right: 2px;
  cursor: pointer;
  background-color: #E61144;
  color: #fff;
}

.custom-item--active {
  background-color: #E9B83B;
  border-radius: 50px;
  color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  min-width: 25px;
  min-height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2px;
  margin-right: 2px;
}
.custom-item--disable {
  opacity: 0.5;
}
.custom-root ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}
.custom-go-item {
  align-items: center;
  display: flex;
  font-size: 12px;
  padding-inline-end: 0.75rem;
  padding-inline-start: 0.75rem;
  width: 150px;
}
.custom-go-item input {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  height: 25px;
  width: 100%;
}
.custom-progress-bar {
  background-color: #9e75ff;
  border-radius: 5px;
  box-shadow: 0 0 4px rgba(174, 0, 255, 0.4);
  height: 3px;
  margin: 1rem 0;
  max-width: 100%;
  transition: width 0.2s ease;
}