@tailwind base;
@tailwind components;
@tailwind utilities;
@import "@fontsource/dm-sans/index.css";
@import "./assets//css/variables.css";
/* 
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  /* code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
   */
*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  height: 100%;
  box-sizing: border-box;
  touch-action: manipulation;
  font-feature-settings: "case" 1, "rlig" 1, "calt" 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto;
  overflow-x: hidden;
}

html,
body {
  font-family: var(--font-sans);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary);
  color: var(--text-primary);
}

body {
  position: relative;
  margin: 0;
  min-height: 100%;
}

a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button:focus {
  outline: none;
}

input:focus {
  box-shadow: none;
  outline: none;
}

.striped-background {
  background: repeating-linear-gradient(
    45deg,
    #161522,
    #161522 10px,
    #0d0415 10px,
    #0d0415 20px
  );
}

/**::-webkit-scrollbar {*/
/*  width: var(--scrollbar-width);*/
/*}*/

/*::-webkit-scrollbar-track-piece:start {*/
/*  background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAQAAAAD+Fb1AAAADklEQVR42mNkgAJG3AwAAH4ABWjFc8IAAAAASUVORK5CYII=) repeat-y !important;*/
/*}*/

/*::-webkit-scrollbar-track-piece:end {*/
/*  background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAQAAAAD+Fb1AAAADklEQVR42mNkgAJG3AwAAH4ABWjFc8IAAAAASUVORK5CYII=) repeat-y !important;*/
/*}*/

/**::-webkit-scrollbar-thumb {*/
/*  border: 3px solid rgba(0, 0, 0, 0);*/
/*  background-clip: padding-box;*/
/*  border-radius: 9999px;*/
/*  background-color: #BFBFBF;*/
/*}*/

/* if backdrop support: very transparent and blurred */
@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .backdrop-blur-fallback {
    background-color: var(--primary);
  }
}

.hide-scrollbar {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #484848;
  border-radius: 7px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e9b83b;
  border-radius: 7px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e9b83b;
}
